<script setup>
import { ref, onMounted } from 'vue';
import { supabase } from '@/composables/SupaBase.js';
import { FormKit } from '@formkit/vue';

import { userSessionStore } from '@/stores/userSession';
const userSession = userSessionStore();

import { useToast } from 'primevue/usetoast';
const toast = useToast();
const emit = defineEmits(['loggedIn']);
const registering = ref(false);

const e = ref(''),
    p = ref(''),
    registerForm = ref({
        name: '',
        playaName: '',
        email: '',
        password: ''
    }),
    formMode = ref('login'),
    currentUrl = window.location.origin;

async function loginWithPassword(credentials) {
    const { data, error } = await supabase.auth.signInWithPassword({
        email: credentials.email,
        password: credentials.password
    });
    if (data?.session?.user) {
        console.log(data.session);
        userSession.session.value = data.session.user;
        toast.add({ severity: 'success', summary: 'Logged in', life: 3000 });
        emit('loggedIn');
        getUserData();
    }
    if (error) {
        console.log('error', error);
        toast.add({ severity: 'error', summary: 'Error', detail: error, life: 30000 });
    }
}

async function resetPassword(credentials) {
    const { data, error } = await supabase.auth.resetPasswordForEmail(credentials.email, {
        redirectTo: currentUrl + '/resetPassword'
    });
    if (data) {
        console.log(data);
        toast.add({ severity: 'success', summary: 'Check your email for a password reset link', life: 3000 });
        emit('loggedIn');
    }
    if (error) {
        console.log('error', error);
        toast.add({ severity: 'error', summary: 'Error', detail: error, life: 30000 });
    }
}

async function signUpWithEmailAndPassword() {
    registering.value = true;
    const { data, error } = await supabase.auth.signUp({
        email: registerForm.value.email,
        password: registerForm.value.password,
        options: {
            emailRedirectTo: currentUrl,
            data: {
                name: registerForm.value.name,
                playaName: registerForm.value.playaName,
                email: registerForm.value.email.toLowerCase()
            }
        }
    });
    if (data) {
        console.log(data);
        if (data?.user?.identities && data?.user?.identities.length > 0) {
            toast.add({ severity: 'success', summary: 'Please confirm your email address', detail: 'Check your inbox for an email from us. Click the link to confirm your account.', life: 10000 });
            formMode.value = 'emailSent';
        } else {
            toast.add({ severity: 'info', summary: 'Hmm', detail: "Are you sure you don't have an account with us already? Try resetting your password", life: 10000 });
            formMode.value = 'resetPassword';
        } // emit('loggedIn');
    }
    if (error) {
        console.log('error', error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Check browser console', life: 3000 });
    }
}

async function getUserData() {
    const { data, error } = await supabase.from('saved_events').select();
    if (error) {
        console.log(JSON.stringify(error, null, 2));
    } else {
        if (data) {
            console.log(JSON.stringify(data, null, 2));
            data.forEach((s) => {
                userSession.savedEvents[s.event_id] = s;
            });
        }
    }
}
</script>
<template>
    <div v-if="formMode === 'login'">
        <div class="grid">
            <div class="col-12 md:col-5">
                <h1 class="font-light text-3xl">Login</h1>
                <FormKit
                    type="form"
                    id="login-form"
                    submit-label="Login"
                    @submit="loginWithPassword"
                    :submit-attrs="{
                        inputClass: 'p-button p-component'
                    }"
                >
                    <FormKit type="primeInputText" label="Email address" validation="required|email" name="email" />

                    <FormKit type="primePassword" label="Password" validation="required" name="password" />
                </FormKit>
            </div>
            <Divider layout="vertical" class="hidden md:block" />
            <Divider class="block md:hidden mt-0 pt-0" />
            <div class="col-12 md:col-6">
                <div class="p-fluid">
                    <h1 class="font-light text-2xl">Create Account</h1>
                    <p class="text-lg">First time here, and want to create an account?</p>
                    <Button label="Create Account" @click="formMode = 'createAccount'" />
                </div>
                <Divider />
                <div class="p-fluid">
                    <h1 class="font-light text-xl">Forgotten your Password?</h1>
                    <p class="text-lg">Get a reset link sent to your email</p>
                    <Button size="small" label="Reset Password" @click="formMode = 'resetPassword'" />
                </div>
            </div>
        </div>
    </div>
    <div v-if="formMode === 'createAccount'">
        <div class="grid">
            <div class="col-6">
                <h1 class="font-light text-3xl">Create Account</h1>
            </div>
            <div class="col-6 flex flex-wrap justify-items-right"><Button text label="Back to login" @click="formMode = 'login'" icon="mdi mdi-reply-outline" /></div>
        </div>

        <FormKit
            type="form"
            submit-label="Create Account"
            @submit="signUpWithEmailAndPassword"
            :submit-attrs="{
                inputClass: 'p-button p-component'
            }"
        >
            <div class="grid mb-0">
                <div class="col-6">
                    <FormKit id="email" name="email" v-model="registerForm.email" type="primeInputText" label="Email address" validation="required|email" />
                </div>
                <div class="col-6">
                    <FormKit id="confEmail" name="email_confirm" type="primeInputText" label="Confirm Email" validation="required | email |confirm" />
                </div>
            </div>
            <div class="grid">
                <div class="col-6">
                    <FormKit id="password" name="password" v-model="registerForm.password" type="primePassword" label="Password" validation="required | length:8,16" help="Between 8 and 16 characters" />
                    <!-- v-tooltip.bottom="'Must contain:\n• Lower-case letters\n• Upper-case letters\n• Digits\n• Special characters'" -->
                </div>
                <div class="col-6">
                    <FormKit id="confPassword" name="password_confirm" type="primePassword" label="Confirm Password" validation="required |confirm" />
                </div>
            </div>
            <div class="grid">
                <div class="col-6">
                    <FormKit id="name" name="name" v-model="registerForm.name" type="primeInputText" label="Name" validation="required" help="Please let us know who you are!" />
                </div>
                <div class="col-6">
                    <FormKit id="playaName" name="playaName" v-model="registerForm.playaName" type="primeInputText" label="Playa name" help="Want to add your playa name?" />
                </div>
                <p class="text-sm mx-3 my-0">
                    <i class="mdi mdi-information mdi-18px"></i> If you register any events to be included in the What Where When Guide, your name will be shown as the "host" of that event. By adding a "Playa Name", that will be shown instead - your
                    real name will stay hidden.
                </p>
            </div>
        </FormKit>
        <Message :closable="false" severity="info" v-if="registering"> Setting up your account now. This takes a few seconds; don't click the 'Create Account' button again! </Message>
    </div>
    <div v-if="formMode === 'resetPassword'">
        <div class="grid">
            <div class="col-8">
                <h1 class="font-light text-3xl">Reset Password</h1>
            </div>
            <div class="col-4"><Button text label="Back to login" @click="formMode = 'login'" icon="mdi mdi-reply-outline" /></div>
        </div>

        <div class="grid mb-0">
            <div class="col-6">
                <FormKit
                    type="form"
                    submit-label="Reset Password"
                    @submit="resetPassword"
                    :submit-attrs="{
                        inputClass: 'p-button p-component'
                    }"
                >
                    <FormKit id="email" name="email" v-model="registerForm.email" type="primeInputText" label="Email address" validation="required|email" />
                </FormKit>
            </div>
            <div class="col-6">
                <div class="p-fluid">
                    <p class="text-lg">Enter the email address you used to create your account and click the 'Reset Password' button.</p>
                    <p class="text-lg">You'll receive an email, with a link to reset your password.</p>
                </div>
            </div>
        </div>
    </div>
    <div v-if="formMode === 'emailSent'">
        <div class="grid">
            <div class="col-8">
                <h1 class="font-light text-3xl">Success</h1>
            </div>
        </div>
        <p>We've sent you a confirmation email, to the email address {{ registerForm.email }}</p>
        <p>Please click the link in that email to activate your account.</p>
    </div>

    <Toast />
</template>
<style lang="scss">
:root {
    --formkit-error-color: #ef9a9a;
}
.formkit-form > .formkit-messages {
    margin: 4px 0;

    .formkit-message {
        font-size: 1rem;
    }
}

.formkit-outer {
    margin: 0;
    padding-bottom: 0.8rem;

    .formkit-inner {
        .p-formkit {
            .p-formkit-icon {
                box-sizing: border-box;
                width: 100%;
            }

            .p-inputtext,
            .p-password,
            .p-multiselect,
            .p-dropdown {
                box-sizing: border-box;
                width: 100%;

                &.p-colorpicker-preview {
                    width: 2rem;
                }
            }
        }
    }

    ul {
        margin-block-start: 2px;
        margin-block-end: 2px;
    }

    .formkit-messages {
        padding: 4px 0 0 0;

        .formkit-message {
            padding: 0;
            margin: 0;
            color: var(--formkit-error-color);
            list-style: none;
            font-size: 0.8rem;
        }
    }
}

.formkit-outer[data-type='primeInputSwitch'],
.formkit-outer[data-type='primeCheckbox'],
.formkit-outer[data-type='primeTriStateCheckBox'],
.formkit-outer[data-type='primeRadioButton'] {
    .formkit-inner {
        .formkit-prime-right {
            margin-left: 10px;
            font-size: 1rem;
        }

        .formkit-prime-left {
            margin-right: 10px;
            font-size: 1rem;
        }
    }
}

.formkit-label {
    display: block;
    margin: 0;
    padding-bottom: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
}

.formkit-fieldset {
    margin: 0;
    padding: 0;
    border-radius: 0;
    border: 0;

    &:focus-within {
        border: 0;
    }

    & > .formkit-help {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.formkit-legend {
    display: block;
    margin: 0;
    padding: 0;
}

.formkit-help {
    margin: 4px 0 0 0;
    font-size: 1rem;
    color: var(--text-color-secondary);
}

.formkit-messages {
    padding: 0;
    font-size: 1rem;
    font-weight: bold;
}

.formkit-message {
    padding: 0;
    margin: 0;
    color: var(--formkit-error-color);
    list-style: none;
    font-size: 1rem;
    font-weight: bold;
}

.formkit-actions {
    padding-top: 0.5rem;
}

.hidden {
    display: none;
}

.block {
    display: block;
}
</style>
