<script setup>
import { computed, watch, ref, onMounted } from 'vue';
import { RouterView } from 'vue-router';
import AppTopbar from './AppTopbar.vue';
// import AppFooter from './AppFooter.vue';
import AppSidebar from './AppSidebar.vue';
import AppConfig from './AppConfig.vue';
import Login from '../views/pages/auth/Login.vue';
import { useLayout } from '@/layout/composables/layout';
import _ from 'lodash';
import { calendarEventDbFields } from '@/composables/definitions.js';
import { supabase } from '@/composables/SupaBase.js';
import { userSessionStore } from '@/stores/userSession';
const userSession = userSessionStore();
import { useLocationStore } from '@/stores/locationStore';
const locationStore = useLocationStore();
import { useCalendarEventStore } from '@/stores/calendarEventStore';
const calendarEventStore = useCalendarEventStore();

// supabase.auth.onAuthStateChange((event, session) => {
//     console.log(event);
//     userSession.session = session;
// });

supabase.auth.onAuthStateChange((event, session) => {
    console.log(event);
    userSession.session = session;
    setTimeout(async () => {
        // await on other Supabase function here
        // this runs right after the callback has finished
        if (event === 'SIGNED_IN') {
            getUserData();
            getUserCalendar();
        }
    }, 0);
});

async function getUserData() {
    const { data, error } = await supabase.from('saved_events').select();
    if (error) {
        console.log(JSON.stringify(error, null, 2));
    } else {
        if (data) {
            userSession.savedEvents = {};
            data.forEach((s) => {
                userSession.savedEvents[s.event_id] = s;
            });
        }
    }
}

async function getUserCalendar() {
    const { data, error } = await supabase.from('calendar_entry').select(calendarEventDbFields).eq('_user_id', userSession.session.user.id);
    if (error) {
        // console.log(error);
    } else {
        // console.log(data);
        if (data.length > 0) {
            calendarEventStore.events = {};
            data.forEach((d) => {
                calendarEventStore.events[d.id] = d;
            });
        }
    }
}
const loginDlg = ref(false);

onMounted(() => {
    getLocations();
});

const { layoutConfig, layoutState, isSidebarActive } = useLayout();

const outsideClickListener = ref(null);

watch(isSidebarActive, (newVal) => {
    if (newVal) {
        bindOutsideClickListener();
    } else {
        unbindOutsideClickListener();
    }
});

async function getLocations() {
    console.log('getting locations');
    const { data, error } = await supabase.from('locations').select().order('name', { ascending: true });
    if (error) {
        console.error('error: ', error);
    } else {
        var locs = {};
        data.forEach((l) => {
            locs[l.id] = l;
        });
        locationStore.locations = data;
        locationStore.mappedLocations = locs;
        // console.log(data, locs);
    }
}

const containerClass = computed(() => {
    return {
        'layout-theme-light': layoutConfig.darkTheme.value === 'light',
        'layout-theme-dark': layoutConfig.darkTheme.value === 'dark',
        'layout-overlay': layoutConfig.menuMode.value === 'overlay',
        'layout-static': layoutConfig.menuMode.value === 'static',
        'layout-static-inactive': layoutState.staticMenuDesktopInactive.value && layoutConfig.menuMode.value === 'static',
        'layout-overlay-active': layoutState.overlayMenuActive.value,
        'layout-mobile-active': layoutState.staticMenuMobileActive.value,
        'p-input-filled': layoutConfig.inputStyle.value === 'filled',
        'p-ripple-disabled': !layoutConfig.ripple.value
    };
});
const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                layoutState.overlayMenuActive.value = false;
                layoutState.staticMenuMobileActive.value = false;
                layoutState.menuHoverActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    const sidebarEl = document.querySelector('.layout-sidebar');
    const topbarEl = document.querySelector('.layout-menu-toggle');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};
</script>

<template>
    <div class="layout-wrapper" :class="containerClass">
        <app-topbar @loginDialog="loginDlg = true"></app-topbar>
        <div class="layout-sidebar">
            <app-sidebar></app-sidebar>
        </div>
        <div class="layout-main-container">
            <div class="layout-main sm:pm0">
                <router-view></router-view>
            </div>
            <!-- <app-footer></app-footer> -->
        </div>

        <!-- <app-config></app-config> -->
        <div class="layout-mask"></div>
    </div>

    <Dialog v-model:visible="loginDlg" modal :style="{ width: '50rem', 'max-width': '600px' }">
        <Login @loggedIn="loginDlg = false" />
    </Dialog>
    <Toast />
</template>

<style lang="scss" scoped>
.pm0 {
    border: 0px;
    padding: 0px;
}
</style>
