import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

export const userSessionStore = defineStore({
    id: 'userSession',
    state: () => ({
        session: null,
        savedEvents: useLocalStorage('savedEvents', {})
    })
});
